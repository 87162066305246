@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.scroll-transition {
  transition: scroll 0.5s ease-in-out; /* Animation de transition pour le défilement */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

.bar-name {
  margin: 0 5px;
  background-color: #fff;

  width: 5px;
  border-radius: 12px;
}

.bar1 {
  height: 10px;
  animation: b1 0.5s infinite alternate;
}

@keyframes b1 {
  0% {
    height: 12px;
  }

  100% {
    height: 20px;
  }
}

.bar2 {
  height: 12px;
  animation: b2 0.5s infinite alternate;
}

@keyframes b2 {
  0% {
    height: 12px;
  }

  100% {
    height: 15px;
  }
}

.bar3 {
  height: 20px;
  animation: b3 0.5s infinite alternate;
}

@keyframes b3 {
  0% {
    height: 20px;
  }

  100% {
    height: 10px;
  }
}

.bar4 {
  height: 15px;
  animation: b4 0.5s infinite alternate;
}

@keyframes b4 {
  0% {
    height: 15px;
  }

  100% {
    height: 20px;
  }
}

.bar5 {
  height: 20px;
  animation: b5 0.5s infinite alternate;
}

@keyframes b5 {
  0% {
    height: 12px;
  }

  100% {
    height: 15px;
  }
}

.bar6 {
  height: 15px;
  animation: b1 0.5s infinite alternate;
}

@keyframes b1 {
  0% {
    height: 15px;
  }

  100% {
    height: 10px;
  }
}

.bar7 {
  height: 4px;
  animation: b7 0.5s infinite alternate;
}

@keyframes b7 {
  0% {
    height: 4px;
  }

  100% {
    height: 10px;
  }
}

.bar8 {
  height: 5px;
  animation: b8 0.5s infinite alternate;
}

@keyframes b8 {
  0% {
    height: 5px;
  }

  100% {
    height: 15px;
  }
}

.bar9 {
  height: 10px;
  animation: b9 0.5s infinite alternate;
}

@keyframes b9 {
  0% {
    height: 12px;
  }

  100% {
    height: 10px;
  }
}

.bar10 {
  height: 35px;
  animation: b10 0.5s infinite alternate;
}

@keyframes b10 {
  0% {
    height: 12px;
  }

  100% {
    height: 20px;
  }
}
